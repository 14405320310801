import $ from 'jquery';
// Vendors JS > ex. import 'bootstrap';
import CustomSlider from "./modules/slider";
import Filters from "./modules/filters";
import App from './modules/app';

// Your Additional 'custom.js' > ex. import Custom from './modules/custom';

window.addEventListener('DOMContentLoaded', function(){
    App.run();
    Filters.run();
    CustomSlider.run();
 });
