import $ from "jquery";

class Filters {
    run() {
        this.filters();
        this.loadMore();

        $('#filter-apply').on('click', function(){
            $('#filters').removeClass('active');
            $('body').removeClass('overflow-hidden');
        });
    }

    filterResult(){
        var slugs = [];
        $('.active-filter[data-slug]').each(function(){
            slugs.push($(this).data('slug'));
            console.log($(this).data('slug'))
        });

        // console.log(slugs);
        var count = 0;

        $('article.item').each(function(){
            var terms = $(this).data('terms');
            var element = $(this);

            element.removeClass('selected');
            element.addClass('d-none');

            if(slugs.length == 0){
                element.addClass('selected');
                count++;
            }

            slugs.forEach(function(item){
                if(terms.includes(item)){
                    element.addClass('selected');
                    count++;
                }
            })
        });

        this.loadMore();
    }

    filters(){
        var that = this;
        var filterCount = 0;
        // CHECK If there are active inputs
        $('#filters input').on('click', function(){

            $(this).toggleClass('active-filter');
            var elem = $("#filters input");
            var count = elem.filter(function() {
                return $(this).val();
            }).length;
            console.log(count)
            if (count == elem.length) {
            } else {
                $('#filters .filters-actions').addClass('active');
            }
            that.filterResult()
        });

    }

    loadMore(){

        var hidden = $("article.item.d-none.selected");
        var i = 0;
        hidden.each(function(){
            if(i < 12) {

                $(this).removeClass("d-none");
                // var src = $(this).find(".main-img").attr("data-src");
                // $(this).find(".main-img").attr("src", src);
            }
            i++;
        });
        // if(i > 12){
        //     $('#loadmore').removeClass('d-none');
        // }
    }
}

const filters = new Filters();
export default filters;