import $ from "jquery";


class App {
	run() {
		this.test()
		this.menu()
		this.reveal()
	}

	test() {
		console.log("It works!");
	}

	menu() {

		document.addEventListener( 'wpcf7mailsent', function( event ) {
			ga('send', 'event', 'Contact Form', 'submit');
		}, false );

		/**
		 * Burger Menu animation
		 */

		$('.burger-menu__icon').click(function(){
			$(this).toggleClass('open');
			$('.burger-menu_list').toggleClass('open-burger');
		});


		$('.read-more').click(function () {
			$(this).parent().find(".team-description").toggleClass('open-more');
		})
	}

	reveal() {

		// reveal items while scrolling the page
			var winWidth = $(window).width();
			var winHeight = $(window).height();
			var tollerance = winHeight / 5;

			if(winWidth < 768 ) {
				tollerance = 0;
			}

			function reveal () {

				var count = 1;

				$('.reveal.not-revealed').each(function () {
					var $this = $(this);

					if($(document).scrollTop() + winHeight - tollerance > $this.offset().top && !$this.attr('revealed')) {

						setTimeout(function () {
							$this.removeClass('not-revealed');
						}, count * 200);

						count++;
					}
				});
			}

			setTimeout(function () {
				reveal();
			}, 150);

			$(document).scroll(function () {
				reveal();
			});

			$(window).resize(function () {
				reveal();
			});
	}
}


const app = new App();
export default app;
