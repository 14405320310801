import $ from 'jquery';
import 'slick-carousel';

class CustomSlider {

    run() {
        this.welcomeSlider();
        this.standardSlider();
        this.servicesSlider();
    }

    welcomeSlider() {
        $('.slider-welcome').slick({
            infinite: true,
            slidesToShow: 1,
            dots: false,
            arrows: false,
            autoplay: true
        });
    }

    standardSlider() {
        $('.about-slider').slick({
            infinite: true,
            slidesToShow: 1,
            dots: false,
            arrows: true,
            nextArrow: '<div class="slick-next slick-arrow"><svg width="54px" height="31px" viewBox="0 0 54 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><g id="02.about" transform="translate(-1304.000000, -1842.000000)" stroke="#000000" stroke-width="2"><g id="Group-8-Copy" transform="translate(1331.500000, 1857.500000) scale(-1, 1) rotate(-270.000000) translate(-1331.500000, -1857.500000) translate(1317.000000, 1832.000000)"><path d="M14.5,49.5 L15,0" id="Line-7"></path><path d="M14.5,50.5 L0.5,36.5" id="Line-8"></path><path d="M14.5,50.5 L28.5,36.5" id="Line-8"></path></g></g></g></svg></div>',
            prevArrow: '<div class="slick-prev slick-arrow"><svg width="54px" height="31px" viewBox="0 0 54 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><g id="02.about" transform="translate(-82.000000, -1842.000000)" stroke="#000000" stroke-width="2"><g id="Group-8" transform="translate(108.500000, 1857.500000) rotate(-270.000000) translate(-108.500000, -1857.500000) translate(94.000000, 1832.000000)"><path d="M14.5,49.5 L15,0" id="Line-7"></path><path d="M14.5,50.5 L0.5,36.5" id="Line-8"></path><path d="M14.5,50.5 L28.5,36.5" id="Line-8"></path></g></g></g></svg></div>',
            breakpoint: 560,
            responsive: [
                {
                    breakpoint: 560,
                    settings: {
                        slidesToShow: 1,
                        dots: true,
                        arrows: false
                    }
                }
            ]
        });
    }


    servicesSlider() {
        $('.services-slider').slick({
            infinite: true,
            autoplay: true,
            slidesToShow: 3,
            dots: false,
            arrows: true,
            nextArrow: '<div class="slick-next slick-arrow"><svg width="54px" height="31px" viewBox="0 0 54 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><g id="02.about" transform="translate(-1304.000000, -1842.000000)" stroke="#000000" stroke-width="2"><g id="Group-8-Copy" transform="translate(1331.500000, 1857.500000) scale(-1, 1) rotate(-270.000000) translate(-1331.500000, -1857.500000) translate(1317.000000, 1832.000000)"><path d="M14.5,49.5 L15,0" id="Line-7"></path><path d="M14.5,50.5 L0.5,36.5" id="Line-8"></path><path d="M14.5,50.5 L28.5,36.5" id="Line-8"></path></g></g></g></svg></div>',
            prevArrow: '<div class="slick-prev slick-arrow"><svg width="54px" height="31px" viewBox="0 0 54 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><g id="02.about" transform="translate(-82.000000, -1842.000000)" stroke="#000000" stroke-width="2"><g id="Group-8" transform="translate(108.500000, 1857.500000) rotate(-270.000000) translate(-108.500000, -1857.500000) translate(94.000000, 1832.000000)"><path d="M14.5,49.5 L15,0" id="Line-7"></path><path d="M14.5,50.5 L0.5,36.5" id="Line-8"></path><path d="M14.5,50.5 L28.5,36.5" id="Line-8"></path></g></g></g></svg></div>',
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 560,
                    settings: {
                        slidesToShow: 1,
                        dots: true,
                        arrows: false
                    }
                }
            ]
        });

        $('.project-slider').slick({
            infinite: true,
            autoplay: true,
            slidesToShow: 3,
            dots: false,
            arrows: true,
            nextArrow: '<div class="slick-next slick-arrow"><svg width="54px" height="31px" viewBox="0 0 54 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><g id="02.about" transform="translate(-1304.000000, -1842.000000)" stroke="#000000" stroke-width="2"><g id="Group-8-Copy" transform="translate(1331.500000, 1857.500000) scale(-1, 1) rotate(-270.000000) translate(-1331.500000, -1857.500000) translate(1317.000000, 1832.000000)"><path d="M14.5,49.5 L15,0" id="Line-7"></path><path d="M14.5,50.5 L0.5,36.5" id="Line-8"></path><path d="M14.5,50.5 L28.5,36.5" id="Line-8"></path></g></g></g></svg></div>',
            prevArrow: '<div class="slick-prev slick-arrow"><svg width="54px" height="31px" viewBox="0 0 54 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><g id="02.about" transform="translate(-82.000000, -1842.000000)" stroke="#000000" stroke-width="2"><g id="Group-8" transform="translate(108.500000, 1857.500000) rotate(-270.000000) translate(-108.500000, -1857.500000) translate(94.000000, 1832.000000)"><path d="M14.5,49.5 L15,0" id="Line-7"></path><path d="M14.5,50.5 L0.5,36.5" id="Line-8"></path><path d="M14.5,50.5 L28.5,36.5" id="Line-8"></path></g></g></g></svg></div>',
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 560,
                    settings: {
                        slidesToShow: 1,
                        dots: true,
                        arrows: false
                    }
                }
            ]
        });
    }

}

const customslider = new CustomSlider();
export default customslider;